import { LoadingButtonProps } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  Stack,
  styled as muiStyled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { m } from 'framer-motion'
import React from 'react'
import { layout } from 'src/theme/globalStyles'
import styled, { css } from 'styled-components'

import { walletInfoDropdownVar } from '@/apollo/localState'
import { WalletTabs } from '@/apollo/types'
import useResponsive from '@/hooks/useResponsive'
import { ReyieldButton } from '@/theme/overrides/Button'

import { MotionViewport, varFade } from '../animate'
import Iconify from '../iconify'

export const maxWidth = layout.maxWidth.desktop + 'px'

export const SectionArea = styled.div`
  width: 100%;
  text-align: center;
  min-height: 70vh;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    min-height: auto !important;
    padding: 50px 0 !important;
    width: ${layout.maxWidth.mobile};
    margin: auto;
  }
`

export const SectionContainer = styled.div`
  margin: auto;
  max-width: ${maxWidth};
  width: 100%;
  padding: ${layout.sectionGap.desktop}px 0;
  @media (max-width: 768px) {
    padding: ${layout.sectionGap.mobile}px 0;
    margin: auto;
    max-width: auto;
    width: ${layout.maxWidth.mobile};
  }
`

interface BackgroundVideoProps {
  desktop: {
    poster: string
    videoSrc: string
  }
  mobile: {
    gifSrc: string
  }
}

const BackgroundSource = styled.source``

const BackgroundVideo = styled.video`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
`

const Img = styled.img`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  z-index: -1;
`

export const BackgroundVideoComponent = (props: BackgroundVideoProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile ? (
    <Img src={props.mobile.gifSrc} />
  ) : (
    <BackgroundVideo autoPlay loop muted poster={props.desktop.poster}>
      <BackgroundSource src={props.desktop.videoSrc} type="video/mp4" />
      <BackgroundSource src={props.desktop.videoSrc} type="video/ogg" />
    </BackgroundVideo>
  )
}

interface IVideoProps {
  style?: React.CSSProperties
  desktop: {
    poster: string
    videoSrc: string
  }
}

interface InnerBackgroundVideoProps extends IVideoProps {
  mobile: {
    gifSrc: string
  }
}

const InnerBackgroundVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.25;
`

const InnerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.25;
`

const VideoVideo = styled.video`
  width: 100%;
  height: 100%;
`

export const InnerBackgroundVideoComponent = (props: InnerBackgroundVideoProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile ? (
    <InnerImg src={props.mobile.gifSrc} style={props.style} />
  ) : (
    <InnerBackgroundVideo autoPlay loop muted poster={props.desktop.poster} style={props.style}>
      <BackgroundSource src={props.desktop.videoSrc} type="video/mp4" />
      <BackgroundSource src={props.desktop.videoSrc} type="video/ogg" />
    </InnerBackgroundVideo>
  )
}

export const VideoComponent = (props: IVideoProps) => (
  <VideoVideo autoPlay loop muted poster={props.desktop.poster} style={props.style}>
    <BackgroundSource src={props.desktop.videoSrc} type="video/mp4" />
    <BackgroundSource src={props.desktop.videoSrc} type="video/ogg" />
  </VideoVideo>
)

interface CardWrapperProps {
  children?: React.ReactNode
  hoverEffect: boolean
}

export const CardContainer: React.FunctionComponent<CardWrapperProps> = (props): JSX.Element => {
  const { children, hoverEffect, ...rest } = props
  return (
    <CardWrapper hoverEffect={hoverEffect} {...rest}>
      {children}
    </CardWrapper>
  )
}

export const commonCss = css`
  backdrop-filter: blur(7.5px);
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background: linear-gradient(
    300deg,
    rgba(225, 225, 225, 0.2) 20%,
    rgba(0, 0, 0, 0.8) 100%
  ) !important;
  border: transparent !important;
  @media (max-width: 768px) {
    padding: 30px;
    grid-gap: 15px;
  }

  && {
    border: 1px solid #ffffff22;
    background-color: #282c34 !important;
    background: linear-gradient(
      300deg,
      rgba(225, 225, 225, 0.2) 20%,
      rgba(0, 0, 0, 0.8) 100%
    ) !important;
    box-shadow: 0 7px 20px 5px #00000088;
    overflow: hidden;
    transition: 1.5s all;
  }

  &:before {
    position: fixed;
    content: '';
    box-shadow: 0 0 30px 100px #ffffff08;
    top: -10%;
    left: -100%;
    transform: rotate(-45deg);
    height: 60rem;
    transition: 1.5s all;
  }
`

export const StyledAccordionSummary = styled.div`
  display: grid;
  grid-gap: ${layout.elementGap.mobile}px;
  grid-template-columns: max-content 1fr;
  align-items: center;
  width: 100%;
`

const CardWrapper = styled(Card)<CardWrapperProps>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  margin: auto;
  position: relative;
  padding: 60px;
  grid-gap: 18px;
  text-align: left;

  ${commonCss}

  ${(props) =>
    props.hoverEffect &&
    css`
      &:hover {
        border: 1px solid #ffffff44;
        box-shadow: 0 7px 50px 10px #000000aa;
        transform: scale(1.015);
        filter: brightness(1.3);
        ::before {
          filter: brightness(0.5);
          top: -100%;
          left: 200%;
        }
      }
    `}
`

interface TopSectionProps {
  title?: string
  subtitle?: string
}

export const TopSection = (props: TopSectionProps) => {
  const { title = '', subtitle = '' } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <MotionViewport>
      <Box
        sx={{
          textAlign: 'left',
          mb: '24px',
        }}
        id={title}
      >
        <m.div variants={varFade().inUp}>
          <Typography variant={isMobile ? 'h3' : 'h1'}>{title}</Typography>
        </m.div>
        {isMobile && <div style={{ height: '4px' }} />}
        <m.div variants={varFade().inDown}>
          <Typography variant={isMobile ? 'body2' : 'h6'} fontWeight="regular">
            {subtitle}
          </Typography>
        </m.div>
      </Box>
    </MotionViewport>
  )
}

export const StyledButton = muiStyled(Button)`
  background: linear-gradient(
    90.47deg,
    rgba(229, 241, 88, 0.8) 9.78%,
    rgba(97, 181, 160, 0.8) 100.45%
  ) !important;
`

export const InsufficientGasFeeWalletWarningButton = (props: LoadingButtonProps) => {
  const theme = useTheme()
  const isMobile = useResponsive('down', 'sm')

  return (
    <ReyieldButton
      onClick={() => {
        walletInfoDropdownVar({
          ...walletInfoDropdownVar(),
          isOpen: true,
          tab: WalletTabs.GAS_FEE_WALLET,
        })
      }}
      fullWidth={isMobile ? true : false}
      sx={{
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
      }}
      variant="outlined"
      {...props}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-around"
        width="100%"
      >
        {isMobile && (
          <>
            <Typography
              variant="p2"
              display="flex"
              alignItems="center"
              justifyContent="space-around"
            >
              <Iconify icon="mingcute:warning-fill" width={18} sx={{ mr: 1 }} />
              Insufficient funds in the gas fee wallet
            </Typography>
            <Iconify icon="uil:arrow-right" width={25} />
          </>
        )}
        {!isMobile && (
          <>
            <Iconify icon="mingcute:warning-fill" width={18} />
            <Typography
              variant="p2"
              display="flex"
              alignItems="center"
              justifyContent="space-around"
            >
              Insufficient funds in the gas fee wallet
            </Typography>
          </>
        )}
      </Stack>
    </ReyieldButton>
  )
}
