import { useRouter } from 'next/router'
import styled from 'styled-components'

import { PATH_AIRDROP, PATH_NFT } from '@/routes/paths'
import reyieldPalette from '@/theme/reyieldPalette'

const MenuListWrapper = styled.div`
  display: flex;
  gap: 40px;
  height: 42px;
  justify-content: space-between;

  @media (max-width: 768px) {
    gap: 40px;
    padding: 12px 20px;
    height: 48px;
    display: flex;
    justify-content: space-between;
  }
`
const MenuItem = styled.div<{ active?: boolean; disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;

  ${({ active }) =>
    active &&
    `
      color: #fff;
      border-bottom: 2px solid ${reyieldPalette().secondary.main};
    `}

  ${({ disabled }) =>
    disabled &&
    `
      color: ${reyieldPalette().primary.light};
      cursor: default;
    `}
  
  &:hover {
    ${({ disabled }) =>
      disabled &&
      `
        border-bottom: initial;
    `}
    ${({ disabled }) =>
      !disabled &&
      `
        border-bottom: 2px solid ${reyieldPalette().secondary.main};
    `}
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 30px;
    font-size: 14px;
    justify-content: flex-start;
  }
`

const Menu = () => {
  const { pathname } = useRouter()
  const router = useRouter()

  return (
    <MenuListWrapper>
      <MenuItem
        active={pathname === PATH_AIRDROP.landing}
        onClick={() => router.push(PATH_AIRDROP.landing)}
      >
        Airdrop
      </MenuItem>

      <MenuItem
        active={pathname === PATH_NFT.landing}
        onClick={() => router.push(PATH_NFT.landing)}
      >
        Pass
      </MenuItem>
    </MenuListWrapper>
  )
}

export default Menu
