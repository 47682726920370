import { Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { layout } from 'src/theme/globalStyles'
import styled from 'styled-components'

import { CardContainer, SectionContainer } from './Shares'

const StyledSectionArea = styled(SectionContainer)`
  justify-content: initial;
  position: relative;
  min-height: auto;
  &:before {
    position: absolute;
    content: ' ';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 'auto';
    width: 100%;
    height: fill-available;
    background-image: url('/images/user-full-control-coin-background.svg');
    @media (max-width: 768px) {
      width: 80%;
    }
  }
  display: grid !important;
  grid-template-areas:
    'user-full-control user-full-control user-full-control-title'
    'user-full-control user-full-control user-full-control-desc';
  @media (max-width: 768px) {
    &:before {
      display: none;
    }
    grid-gap: ${layout.elementGap.mobile}px;
    grid-template-areas:
      'user-full-control-title'
      'user-full-control';
  }
`

const UserFullControlTitleContainer = styled.div`
  grid-area: user-full-control-title;
  width: 100%;
  transform: translate(0, 55%);
  @media (max-width: 768px) {
    transform: translate(0, 0);
    width: 100%;
  }
`
const UserFullControlDescContainer = styled.div`
  grid-area: user-full-control-desc;
  width: 85%;
  transform: translate(0, 15%);
  @media (max-width: 768px) {
    width: 100%;
    transform: translate(0, 0);
  }
`

const UserFullControlImageContainer = styled.div`
  grid-area: user-full-control;
  width: 100%;
  z-index: 1;
`

const StyledVideoImg = styled.img`
  width: 100%;
  border-radius: ${layout.borderRadius.desktop}px;
`

const StyledCardContainer = styled(CardContainer)`
  @media (min-width: 768px) {
    padding-bottom: 100px;
  }
`

const FullControlMobileImg = styled.img`
  width: 100%;
`

const renderText = `Like other DeFi/CeFi, users can use their wallet or exchange. REYIELD offers beginners custodial wallets and broker accounts. Each user gets one wallet and account. REYIELD simplifies DeFi/CeFi investing with full asset transparency.`

const UserFullControl = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledSectionArea>
      <UserFullControlTitleContainer>
        <StyledCardContainer hoverEffect>
          <Typography variant={isMobile ? 'h2' : 'h1'} style={{ whiteSpace: 'pre-line' }}>
            Your Key {'\n'} Your Crypto
          </Typography>
          {isMobile && <Typography variant="body1">{renderText}</Typography>}
          {isMobile && <FullControlMobileImg src="/images/full-control-mobile.svg" />}
        </StyledCardContainer>
      </UserFullControlTitleContainer>
      {!isMobile && (
        <UserFullControlDescContainer>
          <CardContainer hoverEffect>
            <Typography variant="body1" style={isMobile ? {} : { lineHeight: '180%' }}>
              {renderText}
            </Typography>
          </CardContainer>
        </UserFullControlDescContainer>
      )}
      {!isMobile && (
        <UserFullControlImageContainer>
          <StyledVideoImg src="/images/user-full-control.png" />
        </UserFullControlImageContainer>
      )}
    </StyledSectionArea>
  )
}

export default UserFullControl
