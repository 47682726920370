import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { layout } from 'src/theme/globalStyles'
import styled from 'styled-components'

import Iconify from '../iconify/Iconify'
import { CardContainer, SectionContainer, StyledAccordionSummary, TopSection } from './Shares'

enum AreaName {
  first = 'first',
  second = 'second',
  third = 'third',
  forth = 'forth',
}

enum ItemSectionName {
  title = 'title',
  desc = 'desc',
  img = 'img',
}

const StyledCardContainer = styled(CardContainer)`
  padding: 40px;
  height: 100%;
  display: grid;
  grid-gap: 12px;
  grid-template-areas:
    '${ItemSectionName.img} . ${ItemSectionName.title}'
    '${ItemSectionName.img} . ${ItemSectionName.desc}';
  @media (max-width: 768px) {
    grid-gap: ${layout.elementGap.mobile}px;
    grid-template-areas:
      '${ItemSectionName.img} ${ItemSectionName.title}'
      '. ${ItemSectionName.desc}';
  }
`

const StyledSectionContainer = styled(SectionContainer)`
  position: relative;
  &:before {
    position: absolute;
    content: ' ';
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 'auto';
    width: 100%;
    height: fill-available;
    background-image: url('/images/reyield-finance-bg.svg');
    z-index: -1;
  }
  @media (max-width: 768px) {
    &:before {
      display: none;
    }
  }
`

const CardsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    '${AreaName.first} ${AreaName.second}'
    '${AreaName.third} ${AreaName.forth}';
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
    grid-template-areas:
      '${AreaName.first}'
      '${AreaName.second}'
      '${AreaName.third}'
      '${AreaName.forth}';
  }
`

const ImgContainer = styled.div`
  width: 100px;
  height: 100px;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`

const content = {
  title: 'REYIELD Finance',
  subTitle: 'The Web3 Investment Platform',
  items: [
    {
      area: AreaName.first,
      title: 'Verifiable',
      description:
        'Users can use their own exchange account or wallet to verify their investment actions.',
      iconSrc: '/images/verifiable.svg',
    },
    {
      area: AreaName.second,
      title: 'Trustless',
      description: `Users don't need to deposit assets in the REYIELD platform. We offer centralized exchange API integration or wallet login services, and every transaction is managed on-chain through smart contracts.`,
      iconSrc: '/images/trustless.svg',
    },
    {
      area: AreaName.third,
      title: 'Permissionless',
      description:
        'REYIELD is an open platform. Strategy providers can list new strategies anytime.',
      iconSrc: '/images/permissionless.svg',
    },
    {
      area: AreaName.forth,
      title: 'Self-governance',
      description:
        'Token holders have governance rights to decide on reward distribution, broker partners, etc.',
      iconSrc: '/images/self-governance.svg',
    },
  ],
}

const Finance = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledSectionContainer>
      <TopSection title={content.title} subtitle={content.subTitle} />
      <CardsContainer>
        {content.items.map((item, itemIndex) =>
          isMobile ? (
            <Accordion key={item.area + itemIndex}>
              <AccordionSummary
                expandIcon={
                  <Iconify
                    height={40}
                    width={40}
                    icon="material-symbols:keyboard-arrow-down-rounded"
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <StyledAccordionSummary>
                  <StyledImg src={item.iconSrc} />
                  <Typography variant="h5">{item.title}</Typography>
                </StyledAccordionSummary>
              </AccordionSummary>
              <AccordionDetails style={{ padding: '0px 24px 16px 24px' }}>
                <Typography variant="body2" fontWeight="regular">
                  {item.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : (
            <div key={item.area + itemIndex} style={{ gridArea: item.area }}>
              <StyledCardContainer hoverEffect>
                <Typography style={{ gridArea: ItemSectionName.title }} variant="h3">
                  {item.title}
                </Typography>
                <Typography
                  style={{ gridArea: ItemSectionName.desc }}
                  variant="body2"
                  fontWeight="regular"
                >
                  {item.description}
                </Typography>
                <ImgContainer style={{ gridArea: ItemSectionName.img }}>
                  <StyledImg src={item.iconSrc} />
                </ImgContainer>
              </StyledCardContainer>
            </div>
          )
        )}
      </CardsContainer>
    </StyledSectionContainer>
  )
}

export default Finance
