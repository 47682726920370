import React from 'react'
import styled from 'styled-components'

import Rotates from '../animate/Rotates'
import { SectionArea } from './Shares'

const content = {
  items: [
    {
      iconSrc: '/images/1-inch.svg',
      title: 'four connect',
    },
    {
      iconSrc: '/images/binance.svg',
      title: 'entry place',
    },
    {
      iconSrc: '/images/curve.svg',
      title: 'investor',
    },
    {
      iconSrc: '/images/cybavo.svg',
      title: 'investor',
    },
    {
      iconSrc: '/images/convex.svg',
      title: 'entry place',
    },
    {
      iconSrc: '/images/sushiswap.svg',
      title: 'four connect',
    },
  ],
}

const StyledSectionArea = styled(SectionArea)`
  position: relative;
  min-height: auto;
  padding: 0;
  &:before {
    position: absolute;
    content: ' ';
    background-size: contain;
    background-position: center;
    background-image: url('/images/investors.png');
    background-repeat: no-repeat;
    background-size: 'auto';
    width: 400px;
    height: 100%;
    @media (max-width: 768px) {
      width: 80%;
    }
  }
  @media (max-width: 768px) {
    min-height: auto;
    height: 120px;
    overflow: hidden;
    padding: 0;
  }
`

const Investors = () => (
  <StyledSectionArea>
    <Rotates
      switchInterval={3000}
      images={content.items.map((i) => i.iconSrc)}
      desktopDisplayCount={3}
    />
  </StyledSectionArea>
)

export default Investors
