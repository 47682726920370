import { Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import styled from 'styled-components'

import { BackgroundVideoComponent, SectionArea } from './Shares'

const content = {
  title: 'TAP! TAP! YIELD!',
  description:
    'REYIELD is an AI-powered investment platform that streamlines professional investments.',
}

const StyledTypography = styled(Typography)`
  font-size: 110px !important;
  margin-bottom: 40px !important;
  padding: 0 10px;
  @media (max-width: 768px) {
    font-size: 66px !important;
    margin-bottom: 5px !important;
    padding: 0;
  }
`

const StyledSectionArea = styled(SectionArea)`
  height: calc(100vh - 10px);
  overflow: hidden;
  @media (max-width: 768px) {
    height: calc(100vh- 10px);
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
`

const HeroSection = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledSectionArea>
      <BackgroundVideoComponent
        desktop={{
          poster: '/videos/hero-coin-background.png',
          videoSrc: '/videos/hero-coin-background.mp4',
        }}
        mobile={{
          gifSrc: '/videos/hero-coin-background.gif',
        }}
      />
      <TitleContainer>
        {content.title.split(' ').map((t, i) => (
          <StyledTypography key={t + i} variant="h1">
            {t}
          </StyledTypography>
        ))}
      </TitleContainer>
      <Typography
        variant="h6"
        style={isMobile ? { whiteSpace: 'pre-line' } : {}}
        fontWeight={isMobile ? 'regular' : 'bold'}
      >
        {content.description}
      </Typography>
    </StyledSectionArea>
  )
}

export default HeroSection
