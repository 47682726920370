import { Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { layout } from 'src/theme/globalStyles'
import styled, { css } from 'styled-components'

import { CardContainer, commonCss, SectionContainer } from './Shares'

const StyledSectionArea = styled(SectionContainer)`
  justify-content: initial;
  position: relative;
  min-height: auto;
  &:before {
    position: absolute;
    content: ' ';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    width: 100%;
    height: fill-available;
    background-image: url('/images/easy-to-use-background-coin.png');
    transform: translate(0, 5%);
    @media (max-width: 768px) {
      display: none;
    }
  }
  display: grid !important;
  grid-template-areas: '. text video . .';
  grid-gap: ${layout.elementGap.desktop}px;
  @media (max-width: 768px) {
    margin-bottom: 150px;
    grid-gap: ${layout.elementGap.mobile}px;
    grid-template-areas: 'text';
  }
`

const EasyToUseContainer = styled.div`
  grid-area: text;
  width: 88%;
  transform: translate(0, 15%);
  @media (max-width: 768px) {
    width: 100%;
    transform: translate(0, 0);
  }
`

const sharedCss = css`
  transform: translate(0, 0);
  position: absolute;
  width: 90%;
  left: 5%;
`

const VideoContainer = styled.div`
  grid-area: video;
  width: 100%;
  z-index: 1;
  transform: translate(-15%, 5%);

  @media (max-width: 768px) {
    ${sharedCss}
    top: 58%;
  }
`

const StyledVideoImg = styled.img`
  width: 100%;
`

const StyledVideoImgWrapper = styled.div`
  border-radius: 54px;
  overflow: hidden;
  width: 100%;
  transform: scale(1.01);
  @media (max-width: 768px) {
    transform: scale(0.9);
  }
`

const StyledCardContainerBackground = styled.div`
  position: absolute;
  right: 5%;
  bottom: 10%;
  width: 60%;
  height: 60%;
  ${commonCss}
  @media(max-width: 768px) {
    height: 40%;
  }
`

const StyledCardContainer = styled(CardContainer)`
  @media (max-width: 768px) {
    padding-bottom: 40vh;
    margin-bottom: 40vh;
  }
`

const REYIELDGPT = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledSectionArea>
      {!isMobile && <StyledCardContainerBackground />}
      <EasyToUseContainer>
        <StyledCardContainer hoverEffect>
          <Typography style={{ whiteSpace: 'pre-line' }} variant={isMobile ? 'h2' : 'h1'}>
            Investing Redefined by AI
          </Typography>
          <Typography variant="body1" style={isMobile ? {} : { lineHeight: '180%' }}>
            <span style={{ fontWeight: 700 }}>REYIELDGPT: </span> We prioritize your investment
            needs. Through GPT-4's open-ended questioning, REYIELD understands your preferences and
            crafts a personalized plan. You can view our visual charts and select AI-recommended
            strategies.
          </Typography>
          <Typography variant="body1" style={isMobile ? {} : { lineHeight: '180%' }}>
            <span style={{ fontWeight: 700 }}>AI Investment Aid: </span>Become a top Investment
            Influencer effortlessly. We offer market insights and price predictions, helping KOLs
            strategize and equip their subscribers.
          </Typography>
          <Typography variant="body1" style={isMobile ? {} : { lineHeight: '180%' }}>
            <span style={{ fontWeight: 700 }}>LSTM Price Range Discovery: </span> Using an optimized
            LSTM model, we can determine price ranges across various tools, leveraging this data to
            enhance tool performance significantly.
          </Typography>
        </StyledCardContainer>
      </EasyToUseContainer>
      <VideoContainer>
        <StyledVideoImgWrapper>
          <StyledVideoImg src="/videos/REYIELDGPT.gif" />
        </StyledVideoImgWrapper>
      </VideoContainer>
    </StyledSectionArea>
  )
}

export default REYIELDGPT
