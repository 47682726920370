import dynamic from 'next/dynamic'
import styled from 'styled-components'

import { HEADER } from '../../config'
import useOffSetTop from '../../hooks/useOffSetTop'
import Header from './Header'

const Footer = dynamic(() => import('./Footer'), { ssr: false })

const Container = styled.main``
type Props = {
  children?: React.ReactNode
}

export default function CompactLayout({ children }: Props) {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP)

  return (
    <>
      <Header isOffset={isOffset} />
      <Container>{children}</Container>
      <Footer />
    </>
  )
}
