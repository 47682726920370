import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { PATH_FOOTER } from 'src/routes/paths'
import { layout } from 'src/theme/globalStyles'
import styled from 'styled-components'

import palette from '@/theme/palette'

import Iconify from '../iconify'
import {
  CardContainer,
  SectionContainer,
  StyledAccordionSummary,
  StyledButton,
  TopSection,
} from './Shares'

enum SectionAreaEnum {
  image = 'image',
  cardContainer = 'cardContainer',
  top = 'stop',
}

enum AreaName {
  first = 'first',
  second = 'second',
  third = 'third',
  forth = 'forth',
}

enum ItemSectionName {
  title = 'title',
  desc = 'desc',
  img = 'img',
}

const StyledSectionArea = styled(SectionContainer)`
  position: relative;
  display: grid;
  &:before {
    position: absolute;
    content: ' ';
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    width: 100%;
    height: fill-available;
    background-image: url('/images/reyield-token-bg.svg');
    z-index: -1;
  }
  @media (max-width: 768px) {
    &:before {
      display: none;
    }
  }
  grid-template-areas:
    '${SectionAreaEnum.top} ${SectionAreaEnum.top}'
    '${SectionAreaEnum.cardContainer} ${SectionAreaEnum.image}';
  @media (max-width: 768px) {
    grid-template-areas:
      '${SectionAreaEnum.top}'
      '${SectionAreaEnum.cardContainer}';
  }
`

const SectionAreaImage = styled.div`
  grid-area: ${SectionAreaEnum.image};
  width: 300px;
  z-index: 1;
`

const StyledCardContainer = styled(CardContainer)`
  height: 100%;
  display: grid;
  grid-gap: ${layout.elementGap.mobile}px;
  grid-template-areas:
    '${ItemSectionName.img} ${ItemSectionName.title}'
    '. ${ItemSectionName.desc}';
  @media (max-width: 768px) {
    grid-template-areas:
      '${ItemSectionName.img} ${ItemSectionName.title}'
      '. ${ItemSectionName.desc}';
  }
`

const totalWidth = 40
const gapBetweenCard = 10

const StyledCardContainerWrapper = styled.div`
  width: ${100 - totalWidth}%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const CardsContainer = styled.div`
  width: 100%;
  grid-area: ${SectionAreaEnum.cardContainer};
  display: grid;
  grid-template-areas:
    '${AreaName.first}'
    '${AreaName.second}'
    '${AreaName.third}'
    '${AreaName.forth}';

  ${StyledCardContainerWrapper}:nth-child(even) {
    transform: translate(${totalWidth}%, 0);
    margin: -${gapBetweenCard}px 0;
    z-index: 4;
  }
  ${StyledCardContainerWrapper}:nth-child(odd) {
    margin: -${gapBetweenCard}px 0;
    z-index: 5;
  }
  @media (max-width: 768px) {
    grid-gap: 12px;
    grid-template-areas:
      '${AreaName.first}'
      '${AreaName.second}'
      '${AreaName.third}'
      '${AreaName.forth}';

    ${StyledCardContainerWrapper} {
      transform: translate(0, 0) !important;
      margin: 0 !important;
    }
  }
`

const ImgContainer = styled.div`
  width: 50px;
  height: 50px;
`

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`

const content = {
  title: 'Utilities bounded with Benefits',
  subtitle: '',
  items: [
    {
      area: AreaName.first,
      title: 'Reward',
      description:
        'As long as users engage with REYIELD investment platform, they can earn the $REYLD token as an added reward.',
      iconSrc: '/images/reward.svg',
    },
    {
      area: AreaName.second,
      title: 'Privilege',
      description:
        'As long as the specified amount of REYLD is staked, you can use the Cefi and Defi investment tools provided by REYIELD for free while increasing the rewards',
      iconSrc: '/images/privilege.svg',
    },
    {
      area: AreaName.third,
      title: 'Voting',
      description:
        'Decide the distribution of token rewards and the development priority of centralized exchange broker accounts through regular voting.',
      iconSrc: '/images/vote.svg',
    },
    {
      area: AreaName.forth,
      title: 'License Fee',
      description:
        'Pro-investors can rent REYIELD investment tools and collect subscription fees after ordering white-label investment tools to generate profits.',
      iconSrc: '/images/rent.svg',
    },
  ],
}

const StyledContainer = styled.div`
  width: 300px;
  position: absolute;
  right: 0;
  top: 170px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    display: none;
  }
`

const WhitePaperButton = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledButton
      href={PATH_FOOTER.whitepaper}
      variant="contained"
      color="info"
      size="large"
      style={isMobile ? {} : { padding: '16px 20px' }}
    >
      <Iconify
        icon="mdi:paper-outline"
        width={isMobile ? 36 : 28}
        height={isMobile ? 36 : 28}
        style={{ marginRight: 4 }}
      />
      <Typography fontWeight="bold" variant="body1">
        whitepaper
      </Typography>
    </StyledButton>
  )
}

const ReyieldToken = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledSectionArea>
      <TopSection title={content.title} subtitle={content.subtitle} />
      <StyledContainer>
        <WhitePaperButton />
      </StyledContainer>
      <CardsContainer>
        {content.items.map((item, itemIndex) =>
          isMobile ? (
            <Accordion key={itemIndex + item.title}>
              <AccordionSummary
                expandIcon={
                  <Iconify
                    height={40}
                    width={40}
                    icon="material-symbols:keyboard-arrow-down-rounded"
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <StyledAccordionSummary>
                  <StyledImg src={item.iconSrc} />
                  <Typography variant="h5">{item.title}</Typography>
                </StyledAccordionSummary>
              </AccordionSummary>
              <AccordionDetails style={{ padding: '0 24px 16px 24px' }}>
                <Typography variant="body2" fontWeight="regular">
                  {item.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : (
            <StyledCardContainerWrapper key={item.area + itemIndex} style={{ gridArea: item.area }}>
              <StyledCardContainer hoverEffect>
                <Typography style={{ gridArea: ItemSectionName.title }} variant="h2">
                  {item.title}
                </Typography>
                <Typography style={{ gridArea: ItemSectionName.desc }} variant="body2">
                  {item.description}
                </Typography>
                <ImgContainer style={{ gridArea: ItemSectionName.img }}>
                  <StyledImg src={item.iconSrc} />
                </ImgContainer>
              </StyledCardContainer>
            </StyledCardContainerWrapper>
          )
        )}
      </CardsContainer>
      {!isMobile && <SectionAreaImage />}
    </StyledSectionArea>
  )
}

export default ReyieldToken
