import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { m } from 'framer-motion'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface RotatesProps {
  images: string[]
  desktopDisplayCount: number
  switchInterval: number
}

const Container = styled(m.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

const StyledImg = styled(m.img)<{ width: string }>`
  width: ${(props) => props.width};
`

const Rotates = (props: RotatesProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [displayWordIndex, setDisplayWordIndex] = useState(0)
  const counters = isMobile
    ? props.images.length
    : Math.ceil(props.images.length / props.desktopDisplayCount)

  useEffect(() => {
    const timer = setInterval(() => {
      setDisplayWordIndex((index) => {
        const nextIndex = index + 1
        if (nextIndex >= counters) {
          return 0
        } else {
          return nextIndex
        }
      })
    }, props.switchInterval)

    return () => {
      clearInterval(timer)
    }
  }, [props.switchInterval, counters])

  const variants = {
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeOut',
        duration: 1.5,
      },
    },
    hide: {
      y: -20,
      opacity: 0,
    },
  }

  const displayImages = _.slice(
    props.images,
    props.desktopDisplayCount * displayWordIndex,
    props.desktopDisplayCount * displayWordIndex + props.desktopDisplayCount
  )

  return (
    <Container key={displayWordIndex} variants={variants} animate={'show'} initial="hide">
      {isMobile ? (
        <StyledImg width="50%" src={props.images[displayWordIndex]} />
      ) : (
        displayImages.map((displayImage, displayImageIndex) => (
          <StyledImg
            width={`${Math.floor(100 / (displayImages.length + 2))}%`}
            key={displayImageIndex}
            src={displayImage}
          />
        ))
      )}
    </Container>
  )
}

export default Rotates
