import { Avatar, Typography } from '@mui/material'
import React from 'react'
import _mock from 'src/_mock'
import { layout } from 'src/theme/globalStyles'
import styled from 'styled-components'

import Iconify from '../iconify'
import { SectionContainer, TopSection } from './Shares'

const content = {
  title: 'Team',
  subtitle:
    'Team members work under the guidance of team leaders and alongside other team members to ensure that organizational goals are met.',
  teamMembers: [
    {
      jobTitle: 'EXE',
      name: 'Daniel C.',
    },
    {
      jobTitle: 'OPS',
      name: 'Jasmine C.',
    },
    {
      jobTitle: 'Tech',
      name: 'Kyle W.',
    },
    {
      jobTitle: 'BD',
      name: 'Daniel Y.',
    },
    {
      jobTitle: 'BD',
      name: 'Daniel Y.',
    },
    {
      jobTitle: 'BD',
      name: 'Daniel Y.',
    },
    {
      jobTitle: 'BD',
      name: 'Daniel Y.',
    },
    {
      jobTitle: 'BD',
      name: 'Daniel Y.',
    },
    {
      jobTitle: 'BD',
      name: 'Daniel Y.',
    },
  ],
}

const ImagesContainer = styled.div`
  display: grid;
  grid-gap: ${layout.gap.desktop}px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 768px) {
    grid-gap: ${layout.gap.mobile}px;
    grid-template-columns: repeat(2, 1fr);
  }
`

const BackgroundContainer = styled.div`
  position: relative;
  @media (max-width: 768px) {
    padding: ${layout.elementGap.mobile}px;
  }
  &:before {
    position: absolute;
    content: ' ';
    width: 100%;
    top: -50px;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    background: url('/images/team.svg') center center no-repeat;
    background-size: contain;
    background-position: center;
    @media (max-width: 768px) {
      top: 0;
      width: 100%;
      background-position: center;
    }
  }
`

const AvatarWrapper = styled.div``

const TeamContainer = styled.div`
  display: grid;
  grid-gap: ${layout.elementGap.mobile}px;
`

const StyledImg = styled.img`
  width: 50%;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const IconContainer = styled.div`
  display: grid;
  grid-gap: ${layout.elementGap.mobile}px;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Team = () => (
  <SectionContainer>
    <TopSection title={content.title} subtitle={content.subtitle} />
    <BackgroundContainer>
      <ImagesContainer>
        {content.teamMembers.map((item, itemIndex) => (
          <TeamContainer key={itemIndex}>
            <AvatarWrapper>
              <Avatar src={_mock.image.avatar(itemIndex)} sx={{ width: '100%', height: '100%' }} />
            </AvatarWrapper>
            <div>
              <Typography variant="h4">{_mock.role(itemIndex).substring(0, 3)}.</Typography>
              <Typography variant="h3">{_mock.name.lastName(itemIndex)}.</Typography>
            </div>
            <div>
              {[
                {
                  src: '/images/banxa.svg',
                },
                {
                  src: '/images/bitgin.svg',
                },
                {
                  src: '/images/legend-trading.svg',
                },
              ].map((item, itemIndex) => (
                <StyledImg key={itemIndex} src={item.src} />
              ))}
            </div>
            <IconContainer>
              {['mdi:linkedin', 'mdi:twitter', 'mdi:instagram'].map((icon, iconIndex) => (
                <Iconify sx={{ width: '100%', height: '100%' }} icon={icon} key={iconIndex} />
              ))}
            </IconContainer>
          </TeamContainer>
        ))}
      </ImagesContainer>
    </BackgroundContainer>
  </SectionContainer>
)

export default Team
