import { Typography, TypographyProps } from '@mui/material'
import { m } from 'framer-motion'
import React, { useEffect, useState } from 'react'

interface AnimatedWordsProps {
  words: string[]
  renderComponentWords: { text: string[] }[]
  renderComponent?: (text1: string, text2: string) => React.ReactNode
  switchInterval: number
  wordTypographyProps: TypographyProps
}

const AnimatedWordsReplace = (props: AnimatedWordsProps) => {
  const [displayWordIndex, setDisplayWordIndex] = useState(0)
  const [displayComponentWordIndex, setDisplayComponentWordIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setDisplayWordIndex((index) => {
        const nextIndex = index + 1
        if (nextIndex >= props.words.length) {
          return 0
        } else {
          return nextIndex
        }
      })
    }, props.switchInterval)

    const timer2 = setInterval(() => {
      setDisplayComponentWordIndex((index) => {
        const nextIndex = index + 1
        if (nextIndex >= props.renderComponentWords[0].text.length) {
          return 0
        } else {
          return nextIndex
        }
      })
    }, props.switchInterval)

    return () => {
      clearInterval(timer)
      clearInterval(timer2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.switchInterval, props.words.length, props.renderComponentWords.length])

  const variants = {
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeOut',
        duration: 0.3,
      },
    },
    hide: {
      y: -20,
      opacity: 0,
    },
  }

  return (
    <>
      {props.renderComponent && props.renderComponentWords.length ? (
        <m.div key={displayComponentWordIndex} variants={variants} animate={'show'} initial="hide">
          {props.renderComponent(
            props.renderComponentWords[0].text[displayComponentWordIndex] ?? '',
            props.renderComponentWords[1].text[displayComponentWordIndex] ?? ''
          )}
        </m.div>
      ) : (
        <m.div key={displayWordIndex} variants={variants} animate={'show'} initial="hide">
          <Typography {...props.wordTypographyProps}>{props.words[displayWordIndex]}</Typography>
        </m.div>
      )}
    </>
  )
}

export default AnimatedWordsReplace
