import {
  LandingEasyToUse,
  LandingFeature,
  LandingFinance,
  LandingFollowTwitter,
  LandingHero,
  LandingInvestmentStrategies,
  LandingJoinDiscord,
  LandingPartners,
  LandingReyieldGPT,
  LandingReyieldToken,
  LandingRoadmap,
  LandingTokenomic,
  LandingUserFullControl,
} from '../components/home'
import MainLayout from '../layouts/compact'

IndexPage.getLayout = (page: React.ReactElement) => <MainLayout> {page} </MainLayout>

export default function IndexPage() {
  const sections = [
    LandingHero,
    // LandingInvestors,
    LandingFeature,
    LandingEasyToUse,
    LandingUserFullControl,
    LandingReyieldGPT,
    LandingInvestmentStrategies,
    LandingFinance,
    LandingReyieldToken,
    LandingTokenomic,
    LandingJoinDiscord,
    // LandingTeam,
    LandingRoadmap,
    LandingPartners,
    LandingFollowTwitter,
  ]

  return (
    <>
      {sections.map((Section, sectionIndex) => (
        <Section key={sectionIndex} />
      ))}
    </>
  )
}
