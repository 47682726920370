import { m } from 'framer-motion'
import styled from 'styled-components'

const Background = styled(m.div)<{ background: string }>`
  position: absolute;
  width: 500px;
  height: 500px;
  background: ${(props) => props.background};
  opacity: 0.5;
  filter: blur(150px);
  top: calc(50% - 250px);
  left: calc(50% - 250px);
  right: 0;
  bottom: 0;
  z-index: -1;
`

interface AnimatedBackgrounds {
  backgroundColors: string[]
}

const AnimatedBackgrounds = (props: AnimatedBackgrounds) => (
  <>
    {props.backgroundColors.map((background, backgroundIndex) => (
      <Background
        key={background + backgroundIndex}
        background={background}
        animate={
          backgroundIndex % 2 == 0
            ? {
                transform: [
                  'translate(-50%, -50%)',
                  'translate(-50%, 0%)',
                  'translate(50%, 50%)',
                  'translate(50%, -50%)',
                  'translate(-50%, -50%)',
                ],
              }
            : {
                transform: [
                  'translate(50%, 50%)',
                  'translate(50%, 0%)',
                  'translate(-50%, -50%)',
                  'translate(-50%, 50%)',
                  'translate(50%, 50%)',
                ],
              }
        }
        transition={{
          duration: 6,
          ease: 'easeInOut',
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
          repeatDelay: 0,
        }}
      />
    ))}
  </>
)

export default AnimatedBackgrounds
