import { Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { PATH_FOOTER } from 'src/routes/paths'
import { layout } from 'src/theme/globalStyles'
import styled from 'styled-components'

import Iconify from '../iconify'
import { CardContainer, SectionContainer, StyledButton } from './Shares'

const StyledSectionArea = styled(SectionContainer)`
  justify-content: initial;
  position: relative;
  min-height: auto;

  display: grid !important;
`

const StyledCardContainer = styled(CardContainer)`
  height: 100%;
  display: grid;
  grid-gap: ${layout.elementGap.mobile}px;
  grid-template-columns: auto 45%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const JoinDiscord = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledSectionArea>
      <StyledCardContainer hoverEffect={false}>
        <Typography
          textAlign={isMobile ? 'center' : 'left'}
          variant={isMobile ? 'body1' : 'h3'}
          fontWeight={isMobile ? 'bold' : 'bold'}
          style={{ textTransform: 'capitalize' }}
        >
          Join REYIELD's Discord today to discover innovative investment opportunities.
        </Typography>

        <StyledButton
          href={PATH_FOOTER.discord}
          variant="contained"
          color="info"
          size="large"
          style={isMobile ? {} : { padding: '36px 30px' }}
        >
          <Iconify
            icon="bi:discord"
            width={isMobile ? 36 : 42}
            height={isMobile ? 36 : 42}
            style={{ marginRight: 15 }}
          />
          <Typography variant="h6">Join Discord</Typography>
        </StyledButton>
      </StyledCardContainer>
    </StyledSectionArea>
  )
}

export default JoinDiscord
