import React from 'react'
import { layout } from 'src/theme/globalStyles'
import styled from 'styled-components'

import { SectionContainer } from './Shares'

const content = {
  title: 'Partners',
  items: [
    {
      src: '/images/legend-trading.svg',
    },
    {
      src: '/images/banxa.svg',
    },
    {
      src: '/images/cybavo.svg',
    },
    {
      src: '/images/bitgin.png',
    },
  ],
}

const ImagesContainer = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 30px;
  @media (max-width: 768px) {
    grid-row-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
`

const BackgroundContainer = styled.div`
  position: relative;
  @media (max-width: 768px) {
    padding: ${layout.elementGap.mobile}px;
  }
  &:before {
    position: absolute;
    content: ' ';
    width: 100%;
    top: -50px;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    background: url('/images/partners.svg') center center no-repeat;
    background-size: contain;
    background-position: top;
    @media (max-width: 768px) {
      top: 0;
      width: 100%;
      background-position: top;
    }
  }
`

const StyledImg = styled.img`
  width: 100%;
`

const Partners = () => (
  <SectionContainer>
    <BackgroundContainer>
      <ImagesContainer>
        {content.items.map((item, itemIndex) => (
          <StyledImg src={item.src} key={itemIndex} />
        ))}
      </ImagesContainer>
    </BackgroundContainer>
  </SectionContainer>
)

export default Partners
