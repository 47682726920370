import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { layout } from 'src/theme/globalStyles'
import styled from 'styled-components'

import palette from '@/theme/palette'

import Iconify from '../iconify'
import { CardContainer, SectionContainer, StyledAccordionSummary, TopSection } from './Shares'

enum AreaName {
  first = 'first',
  second = 'second',
  third = 'third',
  forth = 'forth',
}

const CardsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    '${AreaName.first} ${AreaName.second} ${AreaName.third}'
    '${AreaName.first} ${AreaName.second} ${AreaName.forth}';
  grid-template-columns: 1fr 1fr 2fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
    grid-template-areas:
      '${AreaName.first}'
      '${AreaName.second}'
      '${AreaName.third}'
      '${AreaName.forth}';
  }
`

const StyledSectionContainer = styled(SectionContainer)`
  position: relative;
  &:before {
    position: absolute;
    content: ' ';
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 'auto';
    width: 100%;
    height: fill-available;
    background-image: url('/images/investment-strategies-bg.svg');
  }
  @media (max-width: 768px) {
    &:before {
      display: none;
    }
  }
`

const StyledImg = styled.img`
  height: 150px;
  @media (max-width: 768px) {
    height: 50px;
  }
`

const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`

const content = {
  items: [
    {
      area: AreaName.first,
      title: 'USDT/ USD Lending',
      subTitle: 'APR 7% - 15% / risk: very low',
      desc: 'Use lending bots to automatically lend USDT/USD to leveraged traders, and to receive the interests provided by the borrowers',
      iconSrc: '/images/single-coin.svg',
      footer: 'Investment Amount Limit: 1B+ USD',
      iconShow: {
        desktop: true,
      },
    },
    {
      area: AreaName.second,
      title: 'Uniswap V3',
      subTitle: 'APR 10% - 50%+ / risk: medium',
      desc: 'The tool automatically adjusts the position and price range on Uniswap V3 to maximize the usage of funds and earnings.',
      iconSrc: '/images/single-coin.svg',
      footer: 'Investment Amount Limit: 10M+ USD',
      iconShow: {
        desktop: true,
      },
    },
    {
      area: AreaName.third,
      title: 'Spot-Futures Arbitrage',
      subTitle: 'APR 8% - 50% / risk: low',
      desc: 'Earn funding rates from futures positions while hedging with spot positions',
      iconSrc: '/images/multi-coin.svg',
      footer: 'Investment Amount Limit: 1B+ USD',
      iconShow: {
        desktop: false,
        special: false,
      },
    },
    {
      area: AreaName.forth,
      title: 'Fiat-Crypto Arbitrage',
      subTitle: 'APR 10% - 30% / risk: very low',
      desc: 'Currently offers Fiat-Crypto Arbitrage for Taiwan users.\n We are looking for similar opportunities in other countries.',
      iconSrc: '/images/multi-coin.svg',
      footer: 'Investment Amount Limit: <100M USD',
      iconShow: {
        desktop: false,
        special: true,
      },
    },
  ],
}

const StyledCardContainer = styled(CardContainer)`
  height: 100%;
  padding: 24px;
`

interface StyledCardContainerWithoutGapProps {
  padding: string
}
const StyledCardContainerWithoutGap = styled(
  StyledCardContainer
)<StyledCardContainerWithoutGapProps>`
  grid-gap: 0;
  ${(props) => `padding: ${props.padding};`}
`

const InvestmentStrategies = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledSectionContainer>
      <TopSection title="Investment Tools & Strategies" subtitle="Automated DeFi and CeFi Tools" />
      <CardsContainer>
        {content.items.map((item, itemIndex) =>
          isMobile ? (
            <Accordion key={itemIndex}>
              <AccordionSummary
                expandIcon={
                  <Iconify
                    height={40}
                    width={40}
                    icon="material-symbols:keyboard-arrow-down-rounded"
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <StyledAccordionSummary>
                  <div
                    style={{
                      display: 'grid',
                      gridGap: layout.elementGap.mobile + 'px',
                      width: '100%',
                    }}
                  >
                    <Typography variant="body1" fontWeight="medium">
                      {item.title}
                    </Typography>
                    <Typography variant="body2">{item.subTitle}</Typography>
                  </div>
                  <StyledImgContainer>
                    <StyledImg src={item.iconSrc ?? '/images/multi-coin.svg'} />
                  </StyledImgContainer>
                </StyledAccordionSummary>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" fontWeight="regular">
                  {item.desc}
                </Typography>
                <div style={{ height: layout.elementGap.mobile + 'px' }} />
                <Typography color={palette('dark').grey[1000]} variant="body2">
                  {item.footer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : (
            <div key={item.area + itemIndex} style={{ gridArea: item.area }}>
              <StyledCardContainerWithoutGap
                padding={itemIndex <= 1 ? '36px 16px' : '24px 16px'}
                hoverEffect
              >
                <div>
                  <Typography variant="h6">{item.title}</Typography>
                  <div style={itemIndex >= 2 ? { height: '24px' } : { height: '46px' }} />
                  <Typography variant="body2">{item.subTitle}</Typography>
                  <div style={itemIndex >= 2 ? { height: '8px' } : { height: '16px' }} />
                  <div style={{ display: 'flex' }}>
                    <Typography
                      color={palette('dark').grey[1000]}
                      fontWeight="light"
                      variant="body2"
                      style={{ fontSize: '14px', whiteSpace: 'pre-line' }}
                    >
                      {item.desc}
                    </Typography>
                    {item.iconShow.special && (
                      <StyledImg
                        style={{
                          height: '150px',
                          position: 'absolute',
                          right: '20px',
                          bottom: '20px',
                        }}
                        src={item.iconSrc}
                      />
                    )}
                  </div>
                </div>
                {item.iconSrc && item.iconShow.desktop && (
                  <StyledImgContainer>
                    <StyledImg src={item.iconSrc} />
                  </StyledImgContainer>
                )}
                <Typography
                  color={palette('dark').grey[1000]}
                  variant="body2"
                  style={{ fontSize: isMobile ? '12px' : '14px' }}
                >
                  {item.footer}
                </Typography>
              </StyledCardContainerWithoutGap>
            </div>
          )
        )}
      </CardsContainer>
    </StyledSectionContainer>
  )
}

export default InvestmentStrategies
