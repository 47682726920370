import { Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import useResponsive from 'src/hooks/useResponsive'
import { layout } from 'src/theme/globalStyles'
import styled from 'styled-components'

import { CardContainer, SectionContainer, TopSection } from './Shares'

enum TokenomicsAreaItemEnum {
  title = 'title',
  pieChart = 'pieChart',
  areaChart = 'areaChart',
}

const TokenomicsAreaContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-gap: ${layout.gap.desktop}px;
  grid-template-areas:
    '${TokenomicsAreaItemEnum.title} ${TokenomicsAreaItemEnum.areaChart}'
    '${TokenomicsAreaItemEnum.pieChart} ${TokenomicsAreaItemEnum.areaChart}';
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      '${TokenomicsAreaItemEnum.title}'
      '${TokenomicsAreaItemEnum.pieChart}'
      '${TokenomicsAreaItemEnum.areaChart}';
  }
`

const TokenomicsAreaItem = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledImg = styled.img`
  width: 100%;
`

const StyledCardContainer = styled(CardContainer)`
  padding: ${layout.elementGap.mobile}px;
  gap: 4px;
`

const TokenomicsArea = () => {
  const isMobile = useResponsive('down', 'sm')
  const tokenNumbers = {
    totalSupply: 10000000000,
  }

  // const series = [44, 55, 13, 43];
  // const chartOptions = useChart({
  //   labels: ['Team A', 'Team B', 'Team C', 'Team D'],
  //   legend: {
  //     show: false,
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     dropShadow: {
  //       enabled: true,
  //     },
  //   },
  //   plotOptions: {
  //     pie: {
  //       donut: {
  //         size: '30%',
  //         background: 'transparent',
  //         labels: {
  //           show: true,
  //           total: {
  //             show: true,
  //             label: 'Total',
  //             formatter: (r) => 'Yield',
  //           },
  //         },
  //       },
  //     },
  //   },
  // });

  const content = {
    title: 'Tokenomics',
    subtitle: '',
    totalSupply: tokenNumbers.totalSupply,
    // labels: [
    //   {
    //     title: 'Founders',
    //     percentage: 8,
    //   },
    //   {
    //     title: 'REYEILD Labs',
    //     percentage: 15,
    //   },
    //   {
    //     title: 'Private Sale',
    //     percentage: 6,
    //   },
    //   {
    //     title: 'Public Sale',
    //     percentage: 1,
    //   },
    //   {
    //     title: 'Initial Liquidity',
    //     percentage: 1,
    //   },
    //   {
    //     title: 'Yield Rewards',
    //     percentage: 69,
    //   },
    // ],
    // series: _.range(1, 17).map((num) => ({
    //   name: `Q${num}`,
    //   data: _.range(1, 17).map((n) => n * 1000 * num * num),
    // })),
  }

  // const areaChartOption = useChart({
  //   legend: {
  //     show: false,
  //   },
  //   xaxis: {
  //     categories: content.series.map((s) => s.name),
  //   },
  //   tooltip: {
  //     enabled: false,
  //   },
  //   series: content.series,
  // });

  return (
    <SectionContainer>
      <TopSection title={content.title} subtitle={content.subtitle} />
      <TokenomicsAreaContainer>
        <div style={{ gridArea: TokenomicsAreaItemEnum.title }}>
          <StyledCardContainer hoverEffect>
            <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
              Total Supply
            </Typography>
            <Typography variant="h3" fontWeight={isMobile ? 'regular' : 'regular'}>
              10,000,000,000 $REYLD
            </Typography>
          </StyledCardContainer>
        </div>
        <TokenomicsAreaItem style={{ gridArea: TokenomicsAreaItemEnum.pieChart }}>
          <StyledImg src="images/pie-chart.png" />
        </TokenomicsAreaItem>
        <TokenomicsAreaItem style={{ gridArea: TokenomicsAreaItemEnum.areaChart }}>
          <StyledImg src="images/area-chart.png" />
        </TokenomicsAreaItem>
      </TokenomicsAreaContainer>
    </SectionContainer>
  )
}

export default TokenomicsArea
