import { Slider, Typography, useMediaQuery, useTheme } from '@mui/material'
import { styled as muiStyled } from '@mui/material/styles'
import _ from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { layout } from 'src/theme/globalStyles'
import styled from 'styled-components'

import { SectionContainer } from './Shares'

const content = {
  title: 'Roadmap',
  subtitle:
    'KOLs can pay rent to rent REYIELD investment tools, and collect subscription fees after ordering white-label investment tools to generate profits.',
}

const marks = _.range(0, 5).map((n) => {
  const mark = {
    value: n,
    label: '',
  }
  return {
    ...mark,
    label: moment('2023-01-01').add(n, 'quarters'),
  }
})

const TextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`

const SliderWrapper = styled.div`
  padding: 50px 150px !important;
  @media (max-width: 768px) {
    padding: ${layout.gap.mobile}px;
  }
`

const height = 600
const StyledRoadmap = styled.div`
  height: ${height}px;
  overflow-y: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #7d7d7d;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #e0ff25;
  }
`
const StyledRoadmapImg = styled.img`
  height: ${height}px;
  max-width: inherit;
`

const StyledItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${layout.elementGap.mobile}px;
  padding: ${layout.elementGap.mobile}px;
  width: 100%;
`

const PrettoSlider = muiStyled(Slider)({
  '& .MuiSlider-thumb': {
    backgroundColor: '#e0ff25',
  },
  '& .MuiSlider-track': {
    color: '#e0ff25',
  },
})

const Roadmap = () => {
  const [activeStepValue, setActiveStepValue] = useState(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const formattedMarks = [
    ...marks.map((mark, markIndex) => ({
      ...mark,
      value: (mark.value / (marks.length - 1)) * 100,
      items:
        markIndex === 0
          ? ['Investment tools on CeFi', 'Fiat gateways']
          : markIndex === 1
          ? ['Closed Beta', 'Investment Tools on DeFi']
          : markIndex === 2
          ? ['Public Sale', 'Open Beta', 'AMM Pool Managing']
          : markIndex === 3
          ? ['Cooperate Fiat exchange Partnerships', 'Invite KOLs to list strategies']
          : ['Launch campaigns to gain more market shares'],
      label: (
        <TextContainer>
          <Typography variant="body1" color="white" textAlign="left">
            {moment(mark.label).format('YYYY') + ' Q' + moment(mark.label).quarter()}
          </Typography>
          <Typography variant="body1" color="white">
            {markIndex === 0
              ? 'Closed Beta'
              : markIndex === 1
              ? 'Open Beta'
              : markIndex === 2
              ? 'Product Launch'
              : markIndex === 3
              ? 'Mass Marketing'
              : markIndex === 4
              ? 'Mass Marketing'
              : ''}
          </Typography>
        </TextContainer>
      ),
    })),
  ].map((mark, markIndex, all) => {
    if (!isMobile) return mark
    // TODO
    return {
      ...mark,
    }
  })

  return (
    <SectionContainer>
      <div style={{ textAlign: isMobile ? 'center' : 'center', width: '100%' }}>
        <Typography variant="h1">{content.title}</Typography>
      </div>
      <div
        style={{
          height: isMobile
            ? layout.elementGap.mobile * 2 + 'px'
            : layout.elementGap.desktop * 2 + 'px',
        }}
      />
      <SliderWrapper
        style={
          !isMobile
            ? { padding: `${layout.gap.desktop}px` }
            : {
                height: '100vh',
                textAlign: 'center',
              }
        }
      >
        <PrettoSlider
          orientation={!isMobile ? 'horizontal' : 'vertical'}
          onChange={(_e, newValue) => {
            if (typeof newValue === 'number') {
              setActiveStepValue(newValue)
            }
          }}
          aria-label="Always visible"
          valueLabelFormat={(valuetext) => {
            const items = _.find(formattedMarks, { value: valuetext })?.items ?? []
            return (
              <StyledItemContainer>
                <ol style={{ padding: 0 }}>
                  {items.map((item, itemIndex) => (
                    <li
                      key={itemIndex + item}
                      style={isMobile ? { fontSize: '12px' } : { fontSize: 20 }}
                    >
                      <Typography
                        style={
                          isMobile
                            ? {
                                textAlign: 'left',
                              }
                            : {}
                        }
                        variant={isMobile ? 'body2' : 'body1'}
                      >
                        {item}
                      </Typography>
                    </li>
                  ))}
                </ol>
              </StyledItemContainer>
            )
          }}
          size="medium"
          step={null}
          marks={formattedMarks}
          valueLabelDisplay="on"
          track={isMobile ? 'normal' : 'normal'}
        />
      </SliderWrapper>
      <div
        style={{
          height: isMobile
            ? layout.elementGap.mobile * 2 + 'px'
            : layout.elementGap.desktop * 2 + 'px',
        }}
      />
      <StyledRoadmap>
        <StyledRoadmapImg src="/images/roadmap.svg" />
      </StyledRoadmap>
    </SectionContainer>
  )
}

export default Roadmap
