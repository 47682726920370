import { Container, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import styled from 'styled-components'

import { MotionViewport } from '../animate'
import AnimatedBackgrounds from '../animate/Backgrounds'
import WordsReplace from '../animate/WordsReplace'
import { SectionArea } from './Shares'

const TextContainer = styled.div`
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 768px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`

const StyledSectionArea = styled(SectionArea)`
  width: 100%;
  position: relative;
  max-width: initial !important;
`

const words = ['']

const Feature = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledSectionArea>
      <Container component={MotionViewport}>
        {!isMobile && <AnimatedBackgrounds backgroundColors={['#C5DD84', '#77C0AE']} />}
        {isMobile ? (
          <>
            <TextContainer>
              <div style={{ display: 'flex', textTransform: 'uppercase' }}>
                <Typography
                  variant="h2"
                  style={{
                    marginRight: 10,
                    whiteSpace: 'nowrap',
                    textTransform: 'uppercase',
                    fontSize: '23px',
                  }}
                >
                  REYIELD provides
                </Typography>
              </div>
              <WordsReplace
                wordTypographyProps={{}}
                switchInterval={3000}
                words={words}
                renderComponentWords={[
                  {
                    text: [
                      'Professional Investment Tools',
                      'Commercialiable Investment Tools',
                      'AI Investment Assistant',
                    ],
                  },
                  {
                    text: [
                      'and makes it as easy as placing an order',
                      'that create an extra revenue stream for influencers and pro traders',
                      'that helps you make better investment decisions',
                    ],
                  },
                ]}
                renderComponent={(text1, text2) => (
                  <>
                    <Typography
                      variant="h1"
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '40px',
                        textAlign: 'left',
                        wordBreak: 'break-word',
                      }}
                      color={'#E5F158'}
                    >
                      {text1}
                    </Typography>
                    <Typography
                      variant="h1"
                      style={{ textTransform: 'uppercase', fontSize: '23px', textAlign: 'left' }}
                    >
                      {text2}
                    </Typography>
                  </>
                )}
              />
            </TextContainer>
          </>
        ) : (
          <>
            <TextContainer>
              <Typography
                variant="h1"
                style={{
                  marginRight: 10,
                  textTransform: 'uppercase',
                  fontSize: '72px',
                  textAlign: 'left',
                }}
              >
                REYIELD provides
              </Typography>
            </TextContainer>
            <WordsReplace
              wordTypographyProps={{}}
              switchInterval={3000}
              words={words}
              renderComponentWords={[
                {
                  text: ['Professional', 'Commercial Investment Tools', 'AI Investment Assistant'],
                },
                {
                  text: [
                    'Investment Tools and makes it as easy as placing an order',
                    'Create an extra revenue stream for influencers and professional traders',
                    'that helps you make better decisions',
                  ],
                },
              ]}
              renderComponent={(text1, text2) => (
                <>
                  <Typography
                    variant="h1"
                    style={{ textTransform: 'uppercase', fontSize: '92px', textAlign: 'left' }}
                    color={'#E5F158'}
                  >
                    {text1}
                  </Typography>
                  <Typography
                    variant="h1"
                    style={{ textTransform: 'uppercase', fontSize: '72px', textAlign: 'left' }}
                  >
                    {text2}
                  </Typography>
                </>
              )}
            />
          </>
        )}
      </Container>
    </StyledSectionArea>
  )
}

export default Feature
