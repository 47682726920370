import { AppBar, Box, BoxProps, Toolbar, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import NextLink from 'next/link'
import Iconify from 'src/components/iconify'
import useResponsive from 'src/hooks/useResponsive'
import { layout } from 'src/theme/globalStyles'

import { ReyieldButton } from '@/theme/overrides/Button'

import { HEADER } from '../../config'
import { PATH_DASHBOARD, PATH_FOOTER } from '../../routes/paths'
import { bgBlur } from '../../utils/cssStyles'
import LandingMenu from '../app/header/Menu'

// ----------------------------------------------------------------------

type Props = {
  isOffset: boolean
}

const LogoContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  height: 80%;
  align-items: center;
  @media (max-width: 768px) {
    height: 100%;
    width: 40%;
  }
`

const StyledLogo = styled('img')`
  width: 40%;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 60%;
  gap: 60px;
  @media (max-width: 768px) {
    width: 55%;
    gap: 15px;
  }
`

const StyledToolbar = styled(Toolbar)`
  max-width: ${layout.maxWidth.desktop}px;
  width: 100%;
  margin: auto;
  background-color: transparent;
  padding: 0 ${layout.elementGap.mobile}px !important;
  @media (max-width: 768px) {
    padding: 0 !important;
    width: ${layout.maxWidth.mobile};
  }
`

const StyledText = styled(Typography)`
  font-size: 16px;
  background: linear-gradient(
    90.47deg,
    rgba(229, 241, 88, 0.8) 9.78%,
    rgba(97, 181, 160, 0.8) 100.45%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const WhitepaperContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledPlainLink = styled('a')`
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
`

const Whitepaper = () => (
  <WhitepaperContainer>
    <StyledPlainLink href={PATH_FOOTER.whitepaper} target="_blank">
      <StyledText variant="body2">Whitepaper</StyledText>
    </StyledPlainLink>
  </WhitepaperContainer>
)

export default function Header({ isOffset }: Props) {
  const theme = useTheme()
  const isMobile = useResponsive('down', 'sm')

  return (
    <AppBar style={{ background: 'rgba(0, 0, 0, 0.8)' }} sx={{ boxShadow: 0 }}>
      <StyledToolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <LogoContainer>
          <NextLink href="/" passHref>
            <StyledLogo src="/logo/reyield.png" />
          </NextLink>
        </LogoContainer>

        <StyledButtonContainer>
          {!isMobile && <LandingMenu />}
          <Whitepaper />
          <NextLink href={PATH_DASHBOARD.explore.investmentTools}>
            <ReyieldButton
              variant="contained"
              endIcon={!isMobile && <Iconify icon="mdi:arrow-right" width={20} />}
              sx={{ width: 'fit-content' }}
            >
              <Typography variant="p2">Dashboard</Typography>
            </ReyieldButton>
          </NextLink>
        </StyledButtonContainer>
      </StyledToolbar>

      {isMobile && <LandingMenu />}

      {isOffset && <Shadow />}
    </AppBar>
  )
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  )
}
